<!-- mechedo aqui agora -->
<script>
import { http } from '@/helpers/easyindustriaapi/config';
import Multiselect from "vue-multiselect";
import stat from './widget.vue';

// verificar se tem saldo suficiente 
// para fazrer a  sangria  

export default {
  page: {
    title: 'Sangria',
    //meta: [{ name: 'description', content: appConfig.description }],
  },
  props: {
    oldCaixa: { type: Object },
    currentUser: { type: Object },
    currentEmpresa:{ type: Object},
    abrir: { type: Boolean }
  },
  components: {
    Multiselect,
    stat
  },

  data() {
    return {
      titleBody: "Sangria",
      item_sangria: [
      ],
      sangria: {
        caixa_id: null,
        nome: null,
        tipo_pagamento_id: null,
        valor: 0,
        movimento_caixa_id: null,
        user_id: null,
        tipo_pagamento: {
          id: -1,
          tiponome: 'selecione',
          tpag: null
        }
      },
      sangrias: [],
      ativa_card: false,
      select_tipo: null,
      vetor_com_exluir: [],
      abrir1: null,
      vet_sem_update: [],
      butao_excluir: false,
      objeto_caixa: null,
      valor_sangria: 0,
      pagamento_sangria: null,
      pagamentos:[],
      empresa_id:null,
      subtracao: false,
      fields: [
        { label: "ID", key: "id", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Nome", key: "nome", tdClass: 'text-left', thClass: 'text-center' },
        { label: "Tipo Pagamento", key: "tipo_pagamento_id", tdClass: 'text-left', thClass: 'text-center' },
        { label: "Valor", key: "valor", tdClass: 'text-right', thClass: 'text-center', formatter: "formatterCurrBR" },
        { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
        { label: "Ações", key: "excluir", tdClass: 'text-center', thClass: 'text-center' },
      ]
    }
  },
  created() {
    //console.log(this.oldCaixa)
    // recebe id do caixa , para não precisar digittar manualmente,
    // ja que o caxa esta na llistagem do index;
    this.empresa_id = this.currentEmpresa.id
    this.sangria.movimento_caixa_id = this.oldCaixa.id
    this.sangria.caixa_id = this.oldCaixa.id
    this.abrir1 = this.abrir
    this.objeto_caixa = this.oldCaixa.total_geral
    this.caixa = this.oldCaixa
    this.get_sangria();
    this.get_tipos_pagamento();
   // this.converterParaReal2()
  },
  mounted() {
    this.$emit('newTitle', this.titleBody);
   
    console.log(this.objeto_caixa)
  },
  methods: {
    async get_tipos_pagamento(){
      let id = this.empresa_id
      try {
        let response = await http.get('/tipopagamento?empresa_id='+id);
        if (response.status === 200) {
          this.pagamentos = response.data;
          //console.log(this.pagamentos)
        }
      } catch (error) {
        let messageErro = error.response.data;
        switch (error.response.status) {
          case 406:
            this.makeToast('danger', 'Erro 406: ' + (messageErro.tipo) ? messageErro.tiponome : messageErro.tpag);
            break;
          case 404:
            this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
            break;
          default:
            this.makeToast('danger', error.message);
            break;
        }
      }
    },
    // usando para o input
    formatInput_valor_sangria() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.valor_sangria.replace(/\D/g, '');
      // Formate o valor numérico para a moeda desejada (por exemplo, BRL)
      this.valor_sangria = parseFloat(numericValue / 100).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
      }).replace("R$", " ");

      this.coverte_para_float_valor_input();
    },

    // ------------------------------------------------------
    // INICIO usado para o input do total caixa_suprimento
    converterParaReal_valor_input() {
      const valor = parseFloat(this.valor_sangria); // Seu valor numérico
      const formatoMoeda = {
        style: "currency",
        currency: "BRL"
      };
      // so funciona quando o numero é enviado de uma vez
      const valorFormatado = valor.toLocaleString("pt-BR", formatoMoeda);
      ///console.log(valorFormatado); // Saída: R$ 1.234,56
      this.valor_sangria = valorFormatado.replace("R$", " ")
    },

    coverte_para_float_valor_input() {
      let stringValor = this.valor_sangria;
      let valorFlutuante = parseFloat(stringValor.replace("R$", " ").replace(/\./g, "").replace(",", "."));
      this.sangria.valor = valorFlutuante
    },
    // ---------------  fim  tot_caixa_input--------------------------------

    select_tipo_pagemnto(objeto) {
      this.pagamento_sangria = objeto.tiponome;
      this.sangria.tipo_pagamento_id = objeto.id;
      this.sangria.tipo_pagamento = objeto;
    },
    makeToast(variant = null, message = 'Error generic') {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: 'b-toaster-bottom-right',
        variant: variant,
        solid: true,
        appendToast: true
      });
    },
    // index , agrora é um objeto;
    async Delete_Sangria(index) {
      let id = index.id;
      
      let response = await http.delete('/sangria/' + id)
        .catch((error) => {
          this.makeToast('danger', error.response);
          this.offLoader();
        });
      if (response.status === 200) {
        this.makeToast('warning', 'Registro excluído');
        this.get_sangria()
        this.get_atualizar_caixa()
      }
    },
    async get_atualizar_caixa(){
      this.subtracao = true;

      try {
        let response = await http.get('movimento-caixa/'+this.oldCaixa.id);
        if (response.status === 200) {
          this.caixa_atualizado = response.data;
          this.caixa = this.caixa_atualizado;
          this.objeto_caixa = this.caixa_atualizado.total_geral;
          this.subtracao = false;
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast('danger', 'Erro 406: ' + (messageErro.tipo) ? messageErro.tiponome : messageErro.tpag);
            break;
          case 404:
            this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
            break;

          default:
            this.makeToast('danger', error.message);
            break;
        }
      }
    },
    async get_sangria() {
      try {
        let response = await http.get('/sangria?movimento_caixa_id='+this.sangria.movimento_caixa_id);

        if (response.status === 200) {
          this.sangrias = response.data;
          //console.log(this.sangrias)
          this.item_sangria = this.sangrias;
          // remove o update e id do movimetno caixa do vetor para exibir de forma correta
          this.removerupdate_att();
          // adiciona a coluna exluir , e muda o tipo pagamento para string;
          this.add_excluir_coluna();
          this.butao_excluir = true;
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast('danger', 'Erro 406: ' + (messageErro.tipo) ? messageErro.tiponome : messageErro.tpag);
            break;
          case 404:
            this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
            break;
          default:
            this.makeToast('danger', error.message);
            break;
        }
      }
    },
    add_excluir_coluna() {
      var itemsComExcluir = this.vet_sem_update.map(function (item) {
        var tipoPagamentoString;
        switch (item.tipo_pagamento_id) {
          case 1:
            tipoPagamentoString = 'Dinheiro';
            break;
          case 2:
            tipoPagamentoString = 'Duplicata';
            break;
          case 3:
            tipoPagamentoString = 'Cheque';
            break;
          case 4:
            tipoPagamentoString = 'Cartão de Credito';
            break;
          case 5:
            tipoPagamentoString = 'Cartão de Debito';
            break;
          case 6:
            tipoPagamentoString = 'Cupom Devolução';
            break;
          case 7:
            tipoPagamentoString = 'transferencia';
            break;
          case 8:
            tipoPagamentoString = 'vale alimentacao';
            break;
          case 9:
            tipoPagamentoString = 'vale refeicao';
            break;
          case 10:
            tipoPagamentoString = 'vale presente';
            break;
          case 11:
            tipoPagamentoString = 'vale combustivel';
            break;
          case 12:
            tipoPagamentoString = 'sem pagamento';
            break;
          default:
            tipoPagamentoString = 'outros';
            break;
        }

        return { ...item, excluir: true, tipo_pagamento_id: tipoPagamentoString };
      });
      this.vet_sem_update = itemsComExcluir;
    },
    // retirar o update_at , 
    // e novo vetor sem o update vai para tabela;
    // removido também o movimento caixa id;
    removerupdate_att() {
      var newItems = this.sangrias.map(function (item) {
        // Cria um novo objeto com os atributos desejados, excluindo 'update_at
        return {
          id: item.id,
          caixa_id: item.caixa_id,
          nome: item.nome,
          tipo_pagamento_id: item.tipo_pagamento_id,
          valor: item.valor,
          data_criacao: item.created_at,
          excluir: item.excluir
        };
      });
      //console.log(newItems)
      this.vet_sem_update = newItems;
      //console.log(this.vet_sem_update)
    },
    async postSangria() {
      const controller = new AbortController();
      this.sangria.user_id = this.currentUser.id;
      let sangria = this.sangria;

      if (sangria.valor > this.objeto_caixa) {
        this.makeToast('danger', 'O valor da Sangria é maior que o total do caixa!');
        controller.abort();
        return false;
      } else {
        this.subtracao = true;
        this.objeto_caixa -= sangria.valor;

        try {
          let response = await http.post('/sangria', sangria)
            .catch((error) => {
              this.error(error.response);
              this.makeToast('danger', error.response);
            });
          if (response.status === 200) {
            this.oldCaixa.total_geral -= sangria.valor;
            this.sangria.tipo_pagamento.tiponome = 'selecione';
            this.ativa_card = true;
            this.makeToast('success', 'Sangria incluída');
            this.sangria.tipo_pagamento_id = null,
            this.select_tipo = null
            this.sangria.nome = null,
            this.sangria.valor = 0,
            this.valor_sangria = null,
            this.pagamento_sangria = null
            this.get_sangria();
           // this.oldCaixa.total_geral -= sangria.valor;
            this.get_tipos_pagamento();
            this.subtracao = false;

            
          }
        } catch (error) {
          this.makeToast('danger', error.response);
        }
      }
    },
    back() {
      this.$emit('back')
    }
  },
}
</script>

<template>
  <!-- @hidden="voltar()" -->
  <b-modal v-model="abrir1" size="xl" title="Sangria" hide-footer @hidden="back()">
    <div class="col-md-12" v-if="!subtracao">
      <stat :item="sangria" :tot_caixa="objeto_caixa" />
    </div>
    <div class="card">
      <b-table-simple>
        <b-thead id="b-head">
          <b-tr>
            <b-th class="b-th">Código do Caixa</b-th>
            <b-th class="b-th">Descricão da Sangria</b-th>
            <b-th class="b-th">Tipo de Pagamento</b-th>
            <b-th class="b-th">Valor R$</b-th>
            <b-th class="b-th">&nbsp;</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td>
              <input disabled v-model="sangria.caixa_id" type="text" class="form-control text-left">
            </b-td>
            <b-td>
              <input v-model="sangria.nome" type="text" class="form-control text-left">
            </b-td>
            <b-td>
              <multiselect v-model="select_tipo" :options="pagamentos" label="tiponome"
                @select="select_tipo_pagemnto($event)">
              </multiselect>
            </b-td>
            <b-td>
              <!-- aguardarDigitacao_valor_input() -->
              <!-- valor_sangria -->
              <!-- coverte para floar quando fizer a requisição -->
              <input v-model="valor_sangria" type="text" class="form-control text-right"
                @input="formatInput_valor_sangria()">
            </b-td>
            <b-td>
              <button class="btn btn-secondary" @click="postSangria()">Incluir</button>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div class="card">
        <div class="card-body">
          <h5>Sangrias Incluídas</h5>
        </div>
        <div>
          <b-table striped hover :items="sangrias" responsive id="sangria-itens"
            :fields="fields"
          >
            <template #cell(data_criacao)="row">
              <span class="text-right">{{ formatDateTimeBR(row.item.data_criacao) }}</span>
            </template>
            <template #cell(excluir)="row">
              <button @click="Delete_Sangria(row.item)" class="btn btn-danger">Excluir</button>
            </template>
            <template #cell(tipo_pagamento_id)="row">
              {{ (row.item.tipo_pagamento_id === 1)  ? 'Dinheiro' 
              :  (row.item.tipo_pagamento_id === 2)  ? 'Duplicata' 
              :  (row.item.tipo_pagamento_id === 3)  ? 'Cheque' 
              :  (row.item.tipo_pagamento_id === 4)  ? 'Cartão de Credito' 
              :  (row.item.tipo_pagamento_id === 5)  ? 'Cartão de Debito' 
              :  (row.item.tipo_pagamento_id === 6)  ? 'Cupom Devolução' 
              :  (row.item.tipo_pagamento_id === 7)  ? 'Transferencia' 
              :  (row.item.tipo_pagamento_id === 8)  ? 'Vale Alimentação' 
              :  (row.item.tipo_pagamento_id === 9)  ? 'Vale Refeição' 
              :  (row.item.tipo_pagamento_id === 10) ? 'Vale Presente' 
              :  (row.item.tipo_pagamento_id === 11) ? 'Vale Combustivel' 
              :  (row.item.tipo_pagamento_id === 12) ? 'Sem Pagamento' : 'Outros' 
                }}
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </b-modal>
</template> 